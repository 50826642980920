import React from 'react'
import './style.css'
import { Img } from './style'
import imgSrc from '../../images/2.png'

export const Hero = ({ tagline }) => {
return (
<div className="hero-container">
    <div><h1 className="hero-tagline">{tagline}</h1></div>
    <p style={{ fontSize:'12pt', fontStyle:'italic'}}>Saya adalah seorang engineer</p>   
    <Img src={imgSrc}/>
</div>)
}