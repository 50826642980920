import React from 'react'
import { Hero } from '../components/Hero'
import  Layout  from '../components/layout'
import { ProjectList } from '../components/Projects'
const Baru = () => {
    return(
    <Layout>
        <Hero tagline="Saya mahasiswa IPB"/>
        <ProjectList />
    </Layout>
    )
}
export default Baru